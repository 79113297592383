<script setup lang="ts">
import { useRoutes } from '@glow/shared/lib/routes';
import SpecializationsBlockItem from './SpecializationsBlockItem.vue';
import { specializations } from '@glow/shared/features/specializations';

const { getUrl } = useRoutes();
</script>

<template>
  <section class="preview-list">
    <NuxtLink
      v-for="specialization in specializations"
      :key="specialization.id"
      :to="
        getUrl('services', {
          specialization: specialization.slug,
        })
      "
    >
      <SpecializationsBlockItem :specialization="specialization" />
    </NuxtLink>
  </section>
</template>

<style scoped lang="scss">
.preview-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;
  }
}
</style>
