<script setup lang="ts">
import type { LinkWithTitleAndPreview } from '@glow/shared/utils/types';
import { useVModel } from '@vueuse/core';
import { mobileAppLinks } from '@glow/shared/lib/constants';

const props = defineProps<{
  modelValue?: boolean;
}>();
defineEmits<{
  (e: 'update:modelValue', modelValue: boolean): void;
}>();

const modelValue = useVModel(props, 'modelValue');
const { staticUrl } = useRuntimeConfig().public;

const storeLinks: LinkWithTitleAndPreview[] = [
  {
    title: 'Google play',
    link: mobileAppLinks.googlePlay,
    imgSrc: `${staticUrl}/images/app/googleplay.png`,
  },
  {
    title: 'App store',
    link: mobileAppLinks.appStore,
    imgSrc: `${staticUrl}/images/app/appstore.png`,
  },
  {
    title: 'App gallery',
    link: mobileAppLinks.appGallery,
    imgSrc: `${staticUrl}/images/app/appgallery.png`,
  },
  {
    title: 'RuStore',
    link: mobileAppLinks.ruStore,
    imgSrc: `${staticUrl}/images/app/rustore.png`,
  },
];
</script>

<template>
  <AppModal v-model="modelValue" :with-close-button="false">
    <div class="download-app-modal">
      <div class="download-app-modal__header">
        <div class="download-app-modal__title">
          Скачивайте наше мобильное приложение
        </div>
        <div class="download-app-modal__subtitle">
          Сканируйте QR-код с помощью телефона
        </div>
      </div>
      <NuxtImg :src="`${staticUrl}/images/app/qr.svg`" width="216" />
      <div class="download-app-modal__stores">
        <NuxtLink
          v-for="[index, store] in storeLinks.entries()"
          :key="index"
          :to="store.link"
          target="_blank"
        >
          <NuxtImg width="32" :src="store.imgSrc" />
        </NuxtLink>
      </div>
      <AppButton fluid variant="outlined" @click="modelValue = false">
        Закрыть
      </AppButton>
    </div>
  </AppModal>
</template>

<style scoped lang="scss">
.download-app-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  &__header {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }

  &__subtitle {
    color: var(--app-color-black-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &__stores {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
  }
}
</style>
