<script setup lang="ts">
export type SpecializationsBlockItemData = {
  id: number;
  name: string;
  slug: string;
};

const props = defineProps<{
  specialization: SpecializationsBlockItemData;
}>();

const { staticUrl } = useRuntimeConfig().public;
const backgroundUrl = toRef(
  () =>
    `url(${staticUrl}/images/specializations/${props.specialization.id}.png)`
);
</script>

<template>
  <div class="preview">
    <div class="preview__name">
      {{ props.specialization.name }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.preview {
  padding: stretch-size(375, 1920, 10, 15);
  border-radius: 16px;
  overflow: hidden;
  aspect-ratio: 254 / 165;
  background:
    linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
    v-bind(backgroundUrl) no-repeat 0 0 / cover;

  &__name {
    color: var(--app-color-white);
    font-size: stretch-size(375, 1920, 13, 24);
    font-weight: 700;
  }
}
</style>
