<script setup lang="ts">
import { NearIcon } from '@glow/shared/components/icons';
import AddressSelectModal from '@/layouts/app/AddressSelectModal.vue';

const { staticUrl } = useRuntimeConfig().public;
const backgroundUrl = toRef(
  () => `url(${staticUrl}/images/home/address-select.png)`
);
const mobileBackgroundUrl = toRef(
  () => `url(${staticUrl}/images/home/address-select-mobile.svg)`
);
const isAddressModalVisible = ref(false);
</script>

<template>
  <section class="block">
    <div class="block__header">
      <h2 class="block__title">
        Идеальный мастер в&nbsp;одном шаге от&nbsp;тебя
      </h2>
      <h3 class="block__subtitle">
        Отличный мастер с&nbsp;отличными отзывами в&nbsp;5 минутах от&nbsp;вас,
        просто укажите свой адрес
      </h3>
    </div>
    <div class="block__input-wrapper">
      <AppInput
        variant="white"
        placeholder="Укажите ваш адрес"
        @click="isAddressModalVisible = true"
      >
        <template #prepend>
          <AppIcon :icon="NearIcon" color="accent" />
        </template>
      </AppInput>
    </div>
    <AddressSelectModal v-model="isAddressModalVisible" />
  </section>
</template>

<style scoped lang="scss">
.block {
  background: var(--app-color-accent-secondary) v-bind(backgroundUrl) 100% 0 /
    contain no-repeat;
  border-radius: 24px;
  padding: stretch-size(375, 1920, 24, 32) stretch-size(375, 1920, 16, 32);
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include media-breakpoint-down(xl) {
    background: var(--app-color-accent-secondary) v-bind(mobileBackgroundUrl)
      100% 50% / 30% no-repeat;
  }

  &__header {
    max-width: 438px;
  }

  &__title {
    font-size: stretch-size(375, 1920, 24, 32);
    font-weight: 700;
    line-height: stretch-size(375, 1920, 28, 36);
    margin-bottom: 9px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;

    @include media-breakpoint-down(md) {
      font-weight: 500;
    }
  }

  &__input-wrapper {
    max-width: 340px;

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
}
</style>
