<script setup lang="ts">
import { useRoutes } from '@glow/shared/lib/routes';

const { getUrl } = useRoutes();
const { staticUrl } = useRuntimeConfig().public;
const backgroundUrl = toRef(() => `url(${staticUrl}/images/home/landing.png)`);
</script>

<template>
  <section class="landing-link section">
    <div class="landing-link__content">
      <h2 class="landing-link__title">Все нужные услуги в&nbsp;одном месте</h2>
      <p class="landing-link__description">
        Узнайте, чем&nbsp;может быть полезен сервис именно для&nbsp;вас
      </p>
    </div>
    <AppButton
      class="landing-link__button"
      color="white"
      :to="getUrl('clientLanding')"
    >
      Узнать о нас больше
    </AppButton>
  </section>
</template>

<style lang="scss" scoped>
.landing-link {
  border-radius: 24px;
  background:
    v-bind(backgroundUrl) 100% 50% / 50% no-repeat,
    linear-gradient(91deg, #a493d8 0%, #5f72e9 100%);
  padding: var(--app-spacing-6) var(--app-spacing-4);
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 24px 16px;
  }

  &__content {
    max-width: 415px;
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      max-width: 350px;
    }

    @include media-breakpoint-down(md) {
      max-width: 250px;
    }
  }

  &__title {
    color: #fff;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 8px;

    @include media-breakpoint-down(lg) {
      font-size: 28px;
      line-height: 32px;
    }

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 27px;
    }
  }

  &__description {
    color: #fff;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      max-width: 160px;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__button {
    margin-top: 20px;
    width: 100%;
  }
}
</style>
